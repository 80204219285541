.hero img {
    width: 100%;
    background-size: cover;
    background-position: center;
}


@media (max-width: 768px) {
    .hero img {
        margin-top: 180px;
    }
}