.ContactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 80vh;
    margin-top: 150px;
  }
  
  .ContactUs h1 {
    color: #171717;
    font-size: 40px;
    font-weight: 600;
    margin-top: 50px;
  }
  
  .ContactUs p {
    color: #171717;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin: 20px 500px;
    width: 65%;
  }
  
  .ContactUs hr {
    width: 100px;
    height: 3px;
    border-radius: 10px;
    background: #ad9451;
  }
  
  @media (max-width: 1600px) {
    .ContactUs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: auto;
    }
  
    .ContactUs h1 {
      color: #171717;
      font-size: 36px;
      font-weight: 600;
      margin-top: 50px;
    }
  
    .ContactUs p {
      color: #171717;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      margin: 20px 500px;
      width: 65%;
    }
  
    .ContactUs hr {
      width: 100px;
      height: 2px;
      border-radius: 5px;
      background: #ad9451;
    }
  }
  
  @media (max-width: 1250px) {
    .ContactUs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: auto;
    }
  
    .ContactUs h1 {
      color: #171717;
      font-size: 32px;
      font-weight: 600;
      margin-top: 50px;
    }
  
    .ContactUs p {
      color: #171717;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      margin: 20px 500px;
      width: 65%;
    }
  
    .ContactUs hr {
      width: 100px;
      height: 2px;
      border-radius: 5px;
      background: #ad9451;
    }
  }
  
  @media (max-width: 960px) {
    .ContactUs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: auto;
    }
  
    .ContactUs h1 {
      color: #171717;
      font-size: 32px;
      font-weight: 600;
      margin-top: 50px;
    }
  
    .ContactUs p {
      color: #171717;
      font-size: 15px;
      align-items: center;
      justify-content: center;
      margin: 20px 500px;
      width: 65%;
    }
  
    .ContactUs hr {
      width: 100px;
      height: 2px;
      border-radius: 5px;
      background: #ad9451;
    }
  }
  
  @media (max-width: 840px) {
    .ContactUs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: auto;
    }
  
    .ContactUs h1 {
      color: #171717;
      font-size: 24px;
      font-weight: 600;
      margin-top: 50px;
    }
  
    .ContactUs p {
      color: #171717;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      margin: 20px 500px;
      width: 65%;
    }
  
    .ContactUs hr {
      width: 100px;
      height: 1px;
      border-radius: 5px;
      background: #ad9451;
    }
  }
  
  @media (max-width: 576px) {
    .ContactUs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height: auto;
    }
  
    .ContactUs h1 {
      color: #171717;
      font-size: 24px;
      font-weight: 600;
      margin-top: 50px;
    }
  
    .ContactUs p {
      color: #171717;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      margin: 20px 500px;
      width: 65%;
    }
  
    .ContactUs hr {
      width: 100px;
      height: 1px;
      border-radius: 5px;
      background: #ad9451;
    }
  }
  