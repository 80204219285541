.loginsignup {
    width: 100%;
    height: 60vh;
    background: #AD9451;
    margin-top: 65px;
}


.loginsignup-container {
    width: 420px;
    height: 420px;
    background: white;
    margin: auto;
    padding: 20px 40px;
}

.loginsignup-container h1 {
    margin: 20px 0px;
}

.loginsignup-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.loginsignup-fields input {
    height: 50px;
    width: 94.5%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    font-size: 16px;
    border-radius: 7px;
}

.loginsignup-container button {
    width: 100%;
    height: 50px;
    color: white;
    background: #ff4141;
    margin-top: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 7px;

}

.loginsignup-login {
    margin-top: 10px;
    color:- #5c5c5c;
    font-size: 13px;
    font-weight:- 500;
    justify-content: center;
    align-items: center;
}

.loginsignup-login span {
    color: #ff4141;
    font-weight: 600;
}

.loginsignup-agree {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
    color: #5c5c5c;
    font-size: 13px;
    font-weight: 500;
}


