* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  padding-left: 5%;
  padding-right: 5%;
}
.btn {
  padding: 14px 22px;
}

@media (max-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .btn {
    padding: 14px 22px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .btn {
    padding: 14px 22px;
  }
}
