.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: auto;
}

.popular h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
    margin-top: 50px;
}

.popular hr {
    width: 100px;
    height: 2px;
    border-radius: 5px;
    background: #AD9451;
}

.popular-item {
    margin-top: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    gap: 100px;
    border: 2px solid #fafafa;
    
}


@media (max-width: 1600px) {
    .popular-item {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 70px;
        font-size: 18px;
        justify-content: center;
        border: 2px solid #fafafa;
        
        
    }

    .popular h1 {
        color: #171717;
        font-size: 36px;
        font-weight: 600;
       
    }

    .popular hr {
        width: 100px;
        height: 2px;
        border-radius: 5px;
        background: #AD9451;
    }
    
    
}

@media (max-width: 1250px) {
    .popular-item {
        grid-template-columns: 1fr 1fr;
        display: grid;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 70px;
        font-size: 16px;
        border: 2px solid #fafafa;
        justify-content: center;
        
    }

    .popular h1 {
        color: #171717;
        font-size: 24px;
        font-weight: 600;
    }
    
    .popular hr {
        width: 100px;
        height: 2px;
        border-radius: 5px;
        background: #AD9451;
    }
}

@media (max-width: 840px) {
    .popular-item {
        grid-template-columns: 1fr;
        display: grid;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 70px;
        font-size: 14px;
        border: 2px solid #fafafa;
        justify-content: center;
        
    }

    .popular h1 {
        color: #171717;
        font-size: 18px;
        font-weight: 600;
        margin-top: 50px;
    }
    
    .popular hr {
        width: 100px;
        height: 2px;
        border-radius: 5px;
        background: #AD9451;
    }
}