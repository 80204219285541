nav {
  width: 100%;
  background: #ffffff;

  padding: 4px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0 1px 10px -2px black;
}

.logo {
  width: 150px;
}

nav ul li {
  display: inline-block;
  list-style: none;
  margin-left: 80px;
  font-size: 16px;
  color: #544540;
  font-weight: 600;
}

nav ul li:hover {
  color: #ad9451;
}

.container {
  padding-left: 7%;
  padding-right: 7%;
}

.menu-icon {
  display: none;
}

@media (max-width: 1000px) {
  .logo {
    width: 150px;
  }

  nav ul li {
    margin: 10px 15px;
  }
}

@media (max-width: 768px) {
  nav {
    padding: 15px 0;
  }

  nav ul {
    position: fixed;
    top: 0;
    right: 0px;
    background: #ad9451;
    z-index: -1;
    width: 200px;
    height: 500px;
    padding-top: 120px;
    transition: 0.5s;
  }

  nav ul li {
    display: block;
    margin: 25px 40px;
    color: #544540;
    font-size: 14px;
    font-weight: 600;
  }

  .logo {
    width: 120px;
  }

  nav ul li:hover {
    color: #ffffff;
  }

  .menu-icon {
    display: block;
    width: 36px;
    cursor: pointer;
  }

  .hide-mobile-menu {
    right: -200px;
  }
}
