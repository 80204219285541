.shopcategory-banner {
    display: block;
    margin: 30px auto;
    width: 82%;
}

.shopcategory-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}

.shopcategory-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}

.shopcategory-indexSort p span {
    font-weight: 600;
}

.shopcategory-products {
    margin: 20px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
}

.shopcategory-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 180px;
    height: 50px;
    border-radius: 75px;
    background: #AD9451;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 1600px) {
    .shopcategory-banner {
        display: block;
        margin: 30px auto;
        width: 65%;
       
    }

    .shopcategory-indexSort {
        display: flex;
        margin: 0px 200px;
        justify-content: space-between;
        align-items: center;
    }

    .shopcategory-sort {
        padding: 5px 15px;
        border-radius: 30px;
        border: 1px solid #888;
    }

    .shopcategory-indexSort p span {
        font-weight: 500;
    }

    .shopcategory-products {
        margin: 20px 170px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 80px;
    }

    .shopcategory-loadmore {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto;
        width: 150px;
        height: 45px;
        border-radius: 65px;
        background: #AD9451;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
    }
    
}

@media (max-width: 1250px) {
    .shopcategory-banner {
        display: block;
        margin: 30px auto;
        width: 65%;
       
    }

    .shopcategory-indexSort {
        display: flex;
        margin: 0px 200px;
        justify-content: space-between;
        align-items: center;
    }

    .shopcategory-sort {
        padding: 5px 15px;
        border-radius: 30px;
        border: 1px solid #888;
    }

    .shopcategory-indexSort p span {
        font-weight: 500;
    }

    .shopcategory-products {
        margin: 20px 170px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 80px;
    }

    .shopcategory-loadmore {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto;
        width: 150px;
        height: 45px;
        border-radius: 65px;
        background: #AD9451;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
    }
    
}

@media (max-width: 840px) {
    .shopcategory-banner {
        display: block;
        margin: 30px auto;
        width: 100%;
       
    }

    .shopcategory-indexSort {
        display: flex;
        margin: 0px 50px;
        justify-content: space-between;
        align-items: center;
    }

    .shopcategory-sort {
        padding: 5px 15px;
        border-radius: 30px;
        border: 1px solid #888;
    }

    .shopcategory-indexSort p span {
        font-weight: 500;
    }

    .shopcategory-products {
        margin: 20px 170px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 80px;
    }

    .shopcategory-loadmore {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto;
        width: 150px;
        height: 45px;
        border-radius: 65px;
        background: #AD9451;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
    }
    
}

@media (max-width: 500px) {
    .shopcategory-banner {
        display: block;
        margin: 30px auto;
        width: 100%;
        margin-top: 150px;
       
    }

    .shopcategory-indexSort {
        display: flex;
        margin: 0px 15px;
        justify-content: space-between;
        align-items: center;
        font-size: 9px;
    }

    .shopcategory-sort {
        padding: 3px 10px;
        border-radius: 20px;
        border: 1px solid #888;
    }

    .shopcategory-indexSort p span {
        font-weight: 400;
    }


    .shopcategory-products {
        grid-template-columns: 1fr;
        display: grid;
        margin-top: 50px;
        margin-bottom: 70px;
        justify-content: center;
        
    }

    .shopcategory-loadmore {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto;
        width: 100px;
        height: 25px;
        border-radius: 65px;
        background: #AD9451;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
    }
    
}

