.newsletter {
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 140px;
    background: linear-gradient(180deg, #AD9451 70%, #544540 -90%);
    gap: 30px;
}

.newsletter h1 {
    color: #454545;
    font-size: 55px;
    font-weight: 600; 
    margin-top: 50px;
}

.newsletter p {
    color: #454545;
    font-size: 20px;

}

.newsletter div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 730px;
    height: auto;
    border-radius: 40px;
    border: 1px solid #e3e3e3;
    margin-bottom: 100px;
}

.newsletter input {
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 20px;
}

.newsletter div button {
    width: 210px;
    height: 70px;
    border-radius: 40px;
    background: black;
    color: white;
    font-size: 20px;
    cursor: pointer;
}


@media (max-width: 1600px) {
    .newsletter h1 {
        color: #454545;
        font-size: 40px;
        font-weight: 600; 
        margin-top: 60px;
    }

    .newsletter div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        width: 630px;
        height: auto;
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        margin-bottom: 100px;
    }
    
    .newsletter input {
        width: 500px;
        margin-left: 30px;
        border: none;
        outline: none;
        color: #616161;
        font-family: Poppins;
        font-size: 20px;
    }
    
    .newsletter div button {
        width: 210px;
        height: 50px;
        border-radius: 30px;
        background: black;
        color: white;
        font-size: 20px;
        cursor: pointer;
    }
    
    .newsletter {
        width: auto;
    }
    
}

@media (max-width: 1250px) {
    .newsletter h1 {
        color: #454545;
        font-size: 36px;
        font-weight: 600; 
        margin-top: 60px;
    }

    .newsletter p {
        color: #454545;
        font-size: 18px;
    
    }

    .newsletter div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        width: 630px;
        height: auto;
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        margin-bottom: 100px;
    }
    
    .newsletter input {
        width: 500px;
        margin-left: 30px;
        border: none;
        outline: none;
        color: #616161;
        font-family: Poppins;
        font-size: 20px;
    }
    
    .newsletter div button {
        width: 210px;
        height: 50px;
        border-radius: 30px;
        background: black;
        color: white;
        font-size: 20px;
        cursor: pointer;
    }
    
    .newsletter {
        width: auto;
    }
    
    
}

@media (max-width: 960px) {
    .newsletter h1 {
        color: #454545;
        font-size: 30px;
        font-weight: 600; 
        margin-top: 60px;
    }
    
    .newsletter p {
        color: #454545;
        font-size: 16px;
    
    }

    .newsletter div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        width: 400px;
        height: auto;
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        margin-bottom: 100px;
    }

    .newsletter input {
        width: 500px;
        margin-left: 30px;
        border: none;
        outline: none;
        color: #616161;
        font-family: Poppins;
        font-size: 18px;
    }
    
    .newsletter div button {
        width: 210px;
        height: 45px;
        border-radius: 30px;
        background: black;
        color: white;
        font-size: 18px;
        cursor: pointer;
    }

    .newsletter {
        width: auto;
    }
    
    
    
    
}

@media (max-width: 840px) {
    .newsletter h1 {
        color: #454545;
        font-size: 24px;
        font-weight: 600; 
        margin-top: 60px;
    }

    .newsletter p {
        color: #454545;
        font-size: 14px;
    
    }

    .newsletter {
        width: auto;
    }
    
    
    
    
}

@media (max-width: 576px) {
    .newsletter h1 {
        color: #454545;
        font-size: 16px;
        font-weight: 600; 
        margin-top: 70px;
        align-items: center;
        justify-content: center;
        width: 300px;
    }

    .newsletter p {
        color: #454545;
        font-size: 12px;
        width: 300px;
    
    }
    
    .newsletter input {
        width: 80px;
        margin-left: 30px;
        border: none;
        outline: none;
        color: #616161;
        font-family: Poppins;
        font-size: 12px;
    }
    
    .newsletter div button {
        width: 80px;
        height: 30px;
        border-radius: 20px;
        background: black;
        color: white;
        font-size: 11px;
        cursor: pointer;
    }

    .newsletter {
        width: auto;
    }
    
    
    
}

