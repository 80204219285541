.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: #544540;
    bottom: 0;
    margin-top: 60px;
}

.logo_footer img {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 200px;
    margin-top: 25px;
}



.footer-links {
    display: flex;
    list-style: none;
    gap: 50px;
    color: #AD9451;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    margin-right: 35px;
}

.footer-links li {
    cursor: pointer;
}

.footer .footer-social-icons {
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
}



.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    color: #AD9451;
    font-size: 14px;
}

.footer-copyright hr {
    width: 100%;
    border: none;
    border-radius: 10px;
    height: 1px;
    background: #AD9451;
}


@media (max-width: 1600px) {
   
    .logo_footer img {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 200px;
        margin-top: 25px;
    }  


    .footer-links {
        display: flex;
        list-style: none;
        gap: 50px;
        color: #AD9451;
        font-size: 14px;
        justify-content: center;
        align-items: center;
    }

    .footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 20px;
        color: #AD9451;
        font-size: 11px;
    }
    
    
}

@media (max-width: 1250px) {
    .logo_footer img {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 200px;
        margin-top: 25px;
    }  


    .footer-links {
        display: flex;
        list-style: none;
        gap: 50px;
        color: #AD9451;
        font-size: 12px;
        justify-content: center;
        align-items: center;
    }

    .footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 20px;
        color: #AD9451;
        font-size: 10px;
    }

    .footer-icon-container {
        display: flex;
        gap: 50px;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 840px) {
    .logo_footer img {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 200px;
        margin-top: 25px;
    }  


    .footer-links {
        display: flex;
        list-style: none;
        gap: 30px;
        color: #AD9451;
        font-size: 11px;
        justify-content: center;
        align-items: center;
    }

    .footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 20px;
        color: #AD9451;
        font-size: 9px;
    }
}